<div mat-dialog-title>
  {{ 'Restore Deleted Staff' | translate }}
</div>
<div mat-dialog-content>
  <div>You're restoring the following staff:</div>
  <div class="restored_confirm">
    <div
      *ngFor="let user of data.users"
      class="restored_confirm__users">
      {{ user.familyName }}, {{ user.givenName }}
    </div>
  </div>

  <div>{{ 'Are you sure you want to restore them? ' | translate }}</div>
</div>
<div mat-dialog-actions>
  <cl-button
    [mat-dialog-close]="false"
    buttonStyle="secondary">
    {{ 'Cancel' | translate }}
  </cl-button>
  <cl-button [mat-dialog-close]="true">{{ 'Yes' | translate }}</cl-button>
</div>

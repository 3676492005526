import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Icon } from '@wam/icons';
import { State } from '@wam/shared';
import { select, Store } from '@ngrx/store';
import * as restoreDeletedUsersActions from '../state/restore-deleted-users.actions';
import * as fromTeacherUsers from '../state/teacher-users.selectors';
import { debounceTime, takeWhile, tap } from 'rxjs/operators';
import { RosteringUser } from '../teacher-users.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Subject } from 'rxjs';
import { difference } from 'lodash-es';
import { RestoreDeletedConfirmDialogComponent } from '../restore-deleted-confirm-dialog/restore-deleted-confirm-dialog.component';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

export enum RestoreDeletedType {
  RESTORE_STAFF,
  RESTORE_STUDENTS,
}

@Component({
  selector: 'restore-deleted-students-dialog',
  templateUrl: './restore-deleted-dialog.component.html',
  styleUrls: ['./restore-deleted-dialog.component.scss'],
})
export class RestoreDeletedDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  Icon = Icon;

  loading$ = this.store.pipe(select(fromTeacherUsers.getLoadingDeletedUsers));
  totalCountDeletedUsers$ = this.store.pipe(select(fromTeacherUsers.getTotalCountDeletedUsers));

  displayedColumns: string[] = ['familyName', 'username'];
  users: RosteringUser[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  searchTerm = '';
  searchTrigger: Subject<void> = new Subject<void>();
  selectedUsers: RosteringUser[] = [];

  private deletedUsers$ = this.store.pipe(select(fromTeacherUsers.getDeletedUsers));
  private componentActive = true;

  constructor(
    public dialogRef: MatDialogRef<RestoreDeletedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { restoreType: RestoreDeletedType },
    private dialog: MatDialog,
    private store: Store<State>,
  ) {}

  ngOnInit(): void {
    this.dispatchLoadUsers();
    this.subscribeToSearch();

    this.deletedUsers$.pipe(takeWhile(() => this.componentActive)).subscribe((users) => {
      this.users = users;
    });
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  ngAfterViewInit() {
    this.sort.sortChange
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.dispatchLoadUsers()),
        takeWhile(() => this.componentActive),
      )
      .subscribe();
  }

  restoreUsers() {
    this.dialogRef.close();
    return this.dialog
      .open(RestoreDeletedConfirmDialogComponent, {
        disableClose: true,
        position: { top: '75px' },
        data: {
          users: this.selectedUsers,
        },
        panelClass: 'no-padding-dialog',
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.store.dispatch(
            restoreDeletedUsersActions.restoreDeletedUsers({
              users: this.selectedUsers,
              restoreType: this.data.restoreType,
            }),
          );
        }
      });
  }

  toggleUsers(users: RosteringUser[], reset = false): void {
    if (reset) {
      this.selectedUsers = this.selectedUsers.length === users.length ? [] : [...users];
    } else {
      this.selectedUsers = [
        ...difference(this.selectedUsers, users),
        ...difference(users, this.selectedUsers),
      ];
    }
  }

  toggleUser(user: RosteringUser): void {
    this.toggleUsers([user]);
  }

  areSelected(users: RosteringUser[]): boolean {
    return difference(users, this.selectedUsers).length === 0;
  }

  isSelected(user: RosteringUser): boolean {
    return this.areSelected([user]);
  }

  getDialogTitle() {
    return this.data.restoreType === RestoreDeletedType.RESTORE_STAFF
      ? marker('Restore Deleted Staff')
      : marker('Restore Deleted Students');
  }

  getSearchLabel() {
    return this.data.restoreType === RestoreDeletedType.RESTORE_STAFF
      ? marker('Select staff to restore:')
      : marker('Select students to restore:');
  }

  private buildOffset() {
    return this.paginator?.pageIndex * this.buildLimit();
  }

  private buildLimit() {
    return this.paginator?.pageSize || 10;
  }

  private dispatchLoadUsers() {
    this.selectedUsers = [];
    this.store.dispatch(
      restoreDeletedUsersActions.loadDeletedUsers({
        restoreType: this.data.restoreType,
        offset: this.buildOffset(),
        limit: this.buildLimit(),
        sortBy: this.sort?.active || 'familyName',
        sortDirection: this.sort?.direction?.toLowerCase() === 'desc' ? 'desc' : 'asc',
        filter: this.searchTerm.trim(),
      }),
    );
  }

  private subscribeToSearch(): void {
    this.searchTrigger
      .asObservable()
      .pipe(
        debounceTime(500),
        takeWhile(() => this.componentActive),
      )
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.dispatchLoadUsers();
      });
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RosteringUser } from '../teacher-users.model';

@Component({
  selector: 'restore-deleted-confirm-dialog',
  templateUrl: './restore-deleted-confirm-dialog.component.html',
  styleUrls: ['./restore-deleted-confirm-dialog.component.scss'],
})
export class RestoreDeletedConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { users: RosteringUser[] }) {}
}

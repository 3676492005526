<div mat-dialog-title>
  {{ getDialogTitle() }}
  <cl-functional-button
    (click)="dialogRef.close(true)"
    [icon]="Icon.cancel"></cl-functional-button>
</div>

<div mat-dialog-content>
  <div
    *ngIf="loading$ | async"
    class="users__loading">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>

  <div>
    <div class="users__searchLabel">{{ getSearchLabel() }}</div>
    <input
      [(ngModel)]="searchTerm"
      (ngModelChange)="searchTrigger.next()"
      id="search"
      placeholder="{{ 'Search by family name...' | translate }}"
      type="text" />
  </div>

  <table
    aria-label="Deleted Users Table"
    class="users__table"
    matSort>
    <thead *ngIf="users.length > 0">
      <tr class="users__table__header">
        <th
          mat-sort-header="familyName"
          scope="column">
          {{ 'Name' | translate }}
        </th>
        <th
          mat-sort-header="username"
          scope="column">
          {{ 'Username' | translate }}
        </th>
      </tr>

      <tr class="users__table__header">
        <td colspan="3">
          <div
            *ngIf="users.length"
            class="teacher-users__select-all">
            <input
              [checked]="areSelected(users)"
              class="styled-checkbox"
              id="select-all-checkbox"
              type="checkbox" />
            <label
              (click)="toggleUsers(users, true); $event.preventDefault()"
              for="select-all-checkbox"
              id="select-all-label">
              {{ 'Select All' | translate }}
            </label>
          </div>
        </td>
      </tr>
    </thead>

    <tbody>
      <tr
        (click)="toggleUser(user); $event.preventDefault()"
        *ngFor="let user of users"
        class="users__table__row">
        <td>
          <input
            [checked]="isSelected(user)"
            class="styled-checkbox"
            id="user_checkbox_{{ user.identifier }}"
            type="checkbox" />
          <label for="user_checkbox_{{ user.identifier }}">
            {{ user.familyName }}, {{ user.givenName }}
          </label>
        </td>
        <td>{{ user.userName }}</td>
      </tr>

      <tr *ngIf="users.length === 0 && !(loading$ | async)">
        <td>There are no deleted users to display.</td>
      </tr>
    </tbody>
  </table>
</div>

<mat-paginator
  [length]="totalCountDeletedUsers$ | async"
  [pageSizeOptions]="[10, 50, 100]"
  showFirstLastButtons></mat-paginator>

<div mat-dialog-actions>
  <cl-button
    (click)="restoreUsers()"
    [disabled]="(loading$ | async) || this.selectedUsers.length === 0"
    id="restore-users-button">
    {{ 'Restore' | translate }}
  </cl-button>
</div>

<ng-template #noUsers>
  <div>
    {{ 'There are no deleted users.' | translate }}
  </div>
</ng-template>

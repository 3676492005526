import { Injectable } from '@angular/core';
import { ApiGatewayService } from '@wam/authentication';
import { forkJoin, Observable } from 'rxjs';
import { map, mergeMap, shareReplay, switchMap } from 'rxjs/operators';
import { State, User, UserRole } from '@wam/shared';
import { Store } from '@ngrx/store';
import {
  Enrollment,
  Gender,
  Grade,
  Language,
  RosteringClass,
  RosteringUser,
  School,
} from './teacher-users.model';
import { StaffRequestModel } from './staff-request.model';
import { UpdateStudentRequestModel } from './update-student-request.model';
import { isEmpty, omitBy } from 'lodash-es';
import { WithAuthService } from '../shared/services/with-auth.service';
import { RestoreDeletedUsersQuery } from './restore-deleted-users-query.model';
import { RestoreDeletedType } from './restore-deleted-dialog/restore-deleted-dialog.component';
import { Tag } from '@app/shared/tag.model';
import { Organization } from '@app/organizations/organization.model';

@Injectable({
  providedIn: 'root',
})
export class RosteringService extends WithAuthService {
  private grades: Observable<Grade[]>;

  constructor(
    private apiGatewayService: ApiGatewayService,
    protected store: Store<State>,
  ) {
    super(store);
  }

  getStudentDetails(studentUUID: string): Observable<RosteringUser> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.simpleGet<{ user: RosteringUser }>(
          `rostering/v2/rostering/source/${user.organization}/students/${studentUUID}`,
        ),
      ),
      map(({ user }) => user),
    );
  }

  getEnrollments(studentUUID: string): Observable<Enrollment[]> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.simpleGet<{ enrollments: Enrollment[] }>(
          `rostering/v2/rostering/source/${user.organization}/students/${studentUUID}/enrollments`,
        ),
      ),
      map(({ enrollments }) => enrollments),
    );
  }

  getGrades(): Observable<Grade[]> {
    if (!this.grades) {
      this.grades = this.apiGatewayService
        .simpleGet<{ grades: Grade[] }>('rostering/v2/rostering/static/grades')
        .pipe(
          map(({ grades }) => grades),
          shareReplay(1),
        );
    }
    return this.grades;
  }

  getLanguages(): Observable<Language[]> {
    return this.apiGatewayService
      .simpleGet<{ languages: Language[] }>('rostering/v2/rostering/static/languages')
      .pipe(map(({ languages }) => languages));
  }

  getGenders(): Observable<Gender[]> {
    return this.apiGatewayService
      .simpleGet<{ genders: Gender[] }>('rostering/v2/rostering/static/genders')
      .pipe(map(({ genders }) => genders));
  }

  getCurrentOrganization(): Observable<Organization> {
    return this.withUser().pipe(
      switchMap(({ organization }) =>
        this.apiGatewayService.simpleGet<{ org: Organization }>(
          `rostering/v2/rostering/source/${organization}`,
        ),
      ),
      map(({ org }) => org),
    );
  }

  getSchools(organization?: string): Observable<School[]> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.simpleGet<{ orgs: School[] }>(
          `rostering/v2/rostering/source/${
            organization ?? user.organization
          }/orgs?filter=type='school'&limit=1000`,
        ),
      ),
      map(({ orgs }) => orgs),
    );
  }

  getClassesInSchool(schoolId: string): Observable<RosteringClass[]> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.simpleGet<{ classes: RosteringClass[] }>(
          `rostering/v2/rostering/source/${user.organization}/orgs/${schoolId}/classes?limit=2000`,
        ),
      ),
      map(({ classes }) => classes),
    );
  }

  createStudent(school, schoolClass, student): Observable<RosteringUser> {
    return this.withUser().pipe(
      switchMap((currentUser: User) =>
        this.apiGatewayService.simplePost(
          `rostering/v2/rostering/source/${currentUser.organization}/orgs/${school}/classes/${schoolClass}/students`,
          omitBy(student, isEmpty),
        ),
      ),
      map(({ user }) => user),
    );
  }

  createStudentEnrollments(
    studentId: string,
    schoolId: string,
    classes: { class: { sourcedId: string }; primary: boolean }[],
  ): Observable<Enrollment[]> {
    return this.withUser().pipe(
      switchMap((currentUser: User) =>
        this.apiGatewayService.simplePost(
          `rostering/v2/rostering/source/${currentUser.organization}/orgs/${schoolId}/students/${studentId}/enrollments`,
          classes,
        ),
      ),
      map(({ enrollments }) => enrollments),
    );
  }

  updateStudent(
    studentUuid: string,
    student: UpdateStudentRequestModel,
  ): Observable<RosteringUser> {
    return this.withUser().pipe(
      switchMap((currentUser: User) =>
        this.apiGatewayService.simplePut<{ user: RosteringUser }>(
          `rostering/v2/rostering/source/${currentUser.organization}/students/${studentUuid}`,
          omitBy(student, isEmpty),
        ),
      ),
      map(({ user }) => user),
    );
  }

  deleteEnrollment(enrollmentId: string) {
    return this.withUser().pipe(
      switchMap((currentUser: User) =>
        this.apiGatewayService.delete(
          `rostering/v2/rostering/source/${currentUser.organization}/enrollments/${enrollmentId}`,
        ),
      ),
      map(() => true),
    );
  }

  deleteStudent(student: string) {
    return this.withUser().pipe(
      switchMap((currentUser: User) =>
        this.apiGatewayService.delete(
          `rostering/v2/rostering/source/${currentUser.organization}/students/${student}`,
        ),
      ),
      map(() => true),
    );
  }

  getTeacherEnrollments(teacherId: string): Observable<Enrollment[]> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.simpleGet<{ enrollments: Enrollment[] }>(
          `rostering/v2/rostering/source/${user.organization}/teachers/${teacherId}/enrollments`,
        ),
      ),
      map(({ enrollments }) => enrollments),
    );
  }

  createTeacherEnrollments(
    teacherId: string,
    schoolId: string,
    classesIds: string[],
  ): Observable<boolean> {
    const classesRequest = [];
    classesIds.forEach((classId) => {
      classesRequest.push({ class: { sourcedId: classId } });
    });

    return this.withUser().pipe(
      switchMap((currentUser: User) =>
        this.apiGatewayService.simplePost(
          `rostering/v2/rostering/source/${currentUser.organization}/orgs/${schoolId}/teachers/${teacherId}/enrollments`,
          classesRequest,
        ),
      ),
      map(() => true),
    );
  }

  createTeacher(teacher: StaffRequestModel, schoolId: string, classId: string) {
    return this.withUser().pipe(
      switchMap((currentUser: User) => {
        return this.apiGatewayService.simplePost(
          `rostering/v2/rostering/source/${currentUser.organization}/orgs/${schoolId}/classes/${classId}/teachers`,
          omitBy(teacher, isEmpty),
        );
      }),
      map(({ user }) => user),
    );
  }

  updateTeacher(teacher: StaffRequestModel) {
    return this.withUser().pipe(
      switchMap((currentUser: User) => {
        return this.apiGatewayService.simplePut(
          `rostering/v2/rostering/source/${currentUser.organization}/teachers/${teacher.sourcedId}`,
          omitBy(teacher, isEmpty),
        );
      }),
      map(({ user }) => user),
    );
  }

  createAdmin(admin: StaffRequestModel, userRole: UserRole) {
    return this.withUser().pipe(
      switchMap((currentUser: User) => {
        const adminUrl = userRole === UserRole.SCHOOL_ADMIN ? 'schooladmins' : 'districtadmins';

        return this.apiGatewayService.simplePost(
          `rostering/v2/rostering/source/${currentUser.organization}/${adminUrl}`,
          omitBy(admin, isEmpty),
        );
      }),
      map(({ user }) => user),
    );
  }

  updateAdmin(admin: StaffRequestModel, userRole: UserRole) {
    return this.withUser().pipe(
      switchMap((currentUser: User) => {
        const adminUrl = userRole === UserRole.SCHOOL_ADMIN ? 'schooladmins' : 'districtadmins';

        return this.apiGatewayService.simplePut(
          `rostering/v2/rostering/source/${currentUser.organization}/${adminUrl}/${admin.sourcedId}`,
          omitBy(admin, isEmpty),
        );
      }),
      map(({ user }) => user),
    );
  }

  deleteStaff(staff: { uuid: string; role?: string }): Observable<void> {
    return this.withUser().pipe(
      mergeMap(({ organization }) => {
        let deleteUrl;
        if (staff?.role === 'teacher') {
          deleteUrl = 'teachers';
        } else if (staff?.role === 'school_admin') {
          deleteUrl = 'schooladmins';
        } else if (staff?.role === 'district_admin') {
          deleteUrl = 'districtadmins';
        }
        return this.apiGatewayService.delete<void>(
          `rostering/v2/rostering/source/${organization}/${deleteUrl}/${staff.uuid}`,
        );
      }),
    );
  }

  getUser(userId: string): Observable<RosteringUser> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.simpleGet<{ user: RosteringUser }>(
          `rostering/v2/rostering/source/${user.organization}/users/${userId}`,
        ),
      ),
      map(({ user }) => user),
    );
  }

  getStaffSchools(href: string): Observable<School> {
    return this.withUser().pipe(
      switchMap(() => this.apiGatewayService.simpleGet<{ org: School }>(href)),
      map(({ org }) => org),
    );
  }

  getDeletedUsers(
    query: RestoreDeletedUsersQuery,
  ): Observable<{ users: RosteringUser[]; totalCount: number }> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.simpleGetWithHeaders<{ users: RosteringUser[] }>(
          `rostering/v2/rostering/source/${user.organization}/users?` +
            `limit=${query.limit}&offset=${query.offset}&sort=${query.sortBy}&orderBy=${query.sortDirection}&` +
            `filter=${this.getNameFilter(query)}status='tobedeleted' AND role='${this.getRole(
              query,
            )}'`,
        ),
      ),
      map((response) => {
        return {
          users: response.body.users,
          totalCount: Number.parseInt(response.headers.get('x-total-count')),
        };
      }),
    );
  }

  getOrganizationTags(): Observable<Tag[]> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.simpleGet(
          `rostering/v2/rostering/source/${user.organization}/custom/tags`,
        ),
      ),
      map((tags: { tags: [] }) => tags.tags),
    );
  }

  saveOrganizationTag(organizationTag: Tag): Observable<Tag> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        organizationTag.sourcedId
          ? this.apiGatewayService.simplePut<{ tag: Tag }>(
              `rostering/v2/rostering/source/${user.organization}/custom/tags/${organizationTag.sourcedId}`,
              organizationTag,
            )
          : this.apiGatewayService.simplePost<{ tag: Tag }>(
              `rostering/v2/rostering/source/${user.organization}/custom/tags`,
              organizationTag,
            ),
      ),
      map(({ tag }) => tag),
    );
  }

  deleteOrganizationTagById(tag: Tag): Observable<Tag> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.delete<Tag>(
          `rostering/v2/rostering/source/${user.organization}/custom/tags/${tag.sourcedId}`,
        ),
      ),
      map((tags) => tags),
    );
  }

  getTagsFor(orgId: string, studentId: string): Observable<Tag[]> {
    return this.apiGatewayService
      .simpleGet<{
        tags: Tag[];
      }>(`rostering/v2/rostering/source/${orgId}/custom/users/${studentId}/tags`)
      .pipe(map(({ tags }) => tags));
  }

  saveTagsFor(orgId: string, studentId: string, userTags: Tag[]): Observable<Tag[]> {
    return this.apiGatewayService.simplePut(
      `rostering/v2/rostering/source/${orgId}/custom/users/${studentId}/tags`,
      {
        tagIds: userTags.map((tag) => tag.sourcedId),
      },
    );
  }

  batchUpdateTags(orgId: string, studentIds: string[], userTags: Tag[]): Observable<Tag[]> {
    return this.apiGatewayService.simplePut(
      `rostering/v2/rostering/source/${orgId}/custom/users/tags`,
      {
        userIds: studentIds,
        tagIds: userTags.map((tag) => tag.sourcedId),
      },
    );
  }

  batchRemoveAllTags(orgId: string, studentIds: string[]): Observable<Tag[]> {
    return this.apiGatewayService.deleteWithBody(
      `rostering/v2/rostering/source/${orgId}/custom/users/tags`,
      {
        userIds: studentIds,
      },
    );
  }

  restoreDeletedUsers(
    users: RosteringUser[],
    restoreType: RestoreDeletedType,
  ): Observable<RosteringUser[]> {
    if (restoreType === RestoreDeletedType.RESTORE_STAFF) {
      return forkJoin(this.updateStaff(users));
    } else {
      return forkJoin(this.updateStudents(users));
    }
  }

  private getRole(query: RestoreDeletedUsersQuery) {
    if (query.restoreType === RestoreDeletedType.RESTORE_STAFF) {
      return 'teacher,school_admin,district_admin';
    } else {
      return 'student';
    }
  }

  private getNameFilter(query: RestoreDeletedUsersQuery) {
    if (isEmpty(query.filter)) {
      return '';
    }
    return `familyName~'${query.filter}' AND `;
  }

  private updateStaff(users: RosteringUser[]): Observable<RosteringUser>[] {
    const updates: Observable<RosteringUser>[] = [];
    for (const user of users) {
      let userModel = {
        familyName: user.familyName,
        givenName: user.givenName,
        sourcedId: user.sourcedId,
        identifier: user.identifier,
        email: user.email,
        status: 'active',
      } as StaffRequestModel;

      if (user.role === 'district_admin') {
        updates.push(this.updateAdmin(userModel, UserRole.DISTRICT_ADMIN));
      } else {
        updates.push(this.updateTeacher(userModel));
      }
    }
    return updates;
  }

  private updateStudents(users: RosteringUser[]): Observable<RosteringUser>[] {
    const updates: Observable<RosteringUser>[] = [];
    for (const user of users) {
      updates.push(
        this.updateStudent(user.sourcedId, {
          familyName: user.familyName,
          givenName: user.givenName,
          preferredName: user.preferredName,
          gender: user.gender,
          grades: user.grades,
          identifier: user.identifier,
          language: user.language,
        }),
      );
    }
    return updates;
  }
}

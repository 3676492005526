import { createAction, props } from '@ngrx/store';
import { RosteringUser } from '../teacher-users.model';
import { RestoreDeletedUsersQuery } from '../restore-deleted-users-query.model';
import { RestoreDeletedType } from '../restore-deleted-dialog/restore-deleted-dialog.component';

export const loadDeletedUsers = createAction(
  '[Restore Deleted Dialog] Load Deleted Users',
  props<RestoreDeletedUsersQuery>(),
);

export const loadUsersSuccess = createAction(
  '[Rostering API] Load Deleted Users Success',
  props<{ users: RosteringUser[]; totalCount: number }>(),
);

export const loadUsersFail = createAction(
  '[Rostering API] Load Deleted Users Fail',
  props<{ error: string }>(),
);

export const restoreDeletedUsers = createAction(
  '[Confirm Restore Deleted Dialog] Restore Deleted Users',
  props<{ users: RosteringUser[]; restoreType: RestoreDeletedType }>(),
);

export const restoreDeletedUsersSuccess = createAction(
  '[Rostering API] Restore Deleted Users Success',
);

export const restoreDeletedUsersFail = createAction(
  '[Rostering API] Restore Deleted Users Fail',
  props<{ error: string }>(),
);

export const cleanDeletedUsersStore = createAction('[Teacher Users Component] Clean store');

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TeacherUsersState } from './teacher-users.state';
import { memoize } from 'lodash-es';

const getTeacherUsersFeatureState = createFeatureSelector<TeacherUsersState>('teacher-users');

export const isLoadingUsers = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.loadingUsers,
);

export const isReloadingUsers = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.reloadingUsers,
);

export const getHits = createSelector(getTeacherUsersFeatureState, (state) => state.hits);

export const getTotalHits = createSelector(getTeacherUsersFeatureState, (state) => state.totalHits);

export const getFacets = createSelector(getTeacherUsersFeatureState, (state) => state.facets);

export const getSelectedFacets = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.selectedFacets,
);

export const getSelectedClass = createSelector(
  getTeacherUsersFeatureState,
  (state: TeacherUsersState) => state.selectedFacets['classes']?.[0],
);

export const getPage = createSelector(getTeacherUsersFeatureState, (state) => state.page);

export const getLevel = createSelector(getTeacherUsersFeatureState, (state) => state.level);

export const getStudentDetails = (studentUuid: string) =>
  createSelector(getTeacherUsersFeatureState, (state) => state.studentDetails?.[studentUuid]);

export const getStudentEnrollments = memoize((studentUuid: string) =>
  createSelector(getTeacherUsersFeatureState, (state) => state.studentEnrollments[studentUuid]),
);

export const getGrades = createSelector(getTeacherUsersFeatureState, (state) => state.grades);

export const getLanguages = createSelector(getTeacherUsersFeatureState, (state) => state.languages);

export const getGenders = createSelector(getTeacherUsersFeatureState, (state) => state.genders);

export const getSchools = createSelector(getTeacherUsersFeatureState, (state) => state.schools);

export const getClasses = memoize((schoolId: string) =>
  createSelector(getTeacherUsersFeatureState, (state) => state.schoolClasses[schoolId]),
);

export const getLoadingEntities = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.loadingEntities,
);

export const getLoadingSchoolClasses = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.loadingSchoolClasses,
);

export const getUuidAlreadyExistent = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.uuidAlreadyExistent,
);

export const getUsernameAlreadyExistent = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.usernameAlreadyExistent,
);

export const getEmailAlreadyExistent = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.emailAlreadyExistent,
);

export const getError = createSelector(getTeacherUsersFeatureState, (state) => state.error);

export const getEntityCreatedSuccessfully = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.entityCreated,
);

export const getEditableStaff = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.editableStaff,
);

export const getEditableStaffSchools = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.editableStaffSchools,
);

export const getEditableStaffClasses = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.editableStaffClasses,
);

export const getStudentAssignments = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.studentAssignments,
);

export const getLoadingDeletedUsers = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.loadingDeletedUsers,
);

export const getDeletedUsers = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.deletedUsers,
);

export const getTotalCountDeletedUsers = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.totalCountDeletedUsers,
);

export const getOrganizationTags = createSelector(
  getTeacherUsersFeatureState,
  (state) => state.organizationTags,
);
